<template>
  <div class="w-full flex flex-col items-center alpha-bottom from-black">
    <div
      class="w-full max-w-7xl flex flex-col gap-3 sm:flex-row items-center justify-between py-3 px-2 xl:px-0"
    >
      <NuxtLink to="/" class="-mb-1">
        <img :src="logoUrl" alt="Logo" class="w-full max-w-[150px]" />
      </NuxtLink>

      <div
        class="w-full flex flex-row items-center justify-center sm:justify-end gap-3"
      >
        <NuxtLink class="link" :to="joinUrl">Movies</NuxtLink>
        <a
          v-if="currentSite.showTopRatedVideos"
          class="link"
          href="/tours/top-rated"
        >
          Top Rated
        </a>
        <NuxtLink class="link" to="/models">Models</NuxtLink>
        <NuxtLink v-if="hasExclusiveSeries" class="link" to="/sites">
          Sites
        </NuxtLink>

        <UButton
          v-if="!inMembersArea"
          color="white"
          variant="outline"
          class="uppercase text-lg text-white"
          to="/login"
        >
          Login
        </UButton>

        <UButton
          v-if="!inMembersArea"
          variant="solid"
          color="green"
          class="uppercase text-lg text-white"
          :to="joinUrl"
        >
          Join Now
        </UButton>
      </div>
    </div>
    <Divider />
  </div>
</template>

<script>
  export default {
    name: "TourClassicNavigation",
  }
</script>

<script setup>
  const currentSite = inject("currentSite")
  const logoUrl = `/images/sites/${currentSite.value.slug}/logo.png`
  const joinUrl = currentSite.value.natsJoinUrl
  const { inMembersArea } = useMembersArea()
  const { hasExclusiveSeries } = useSiteSettings(currentSite.value)
</script>

<style scoped>
  .link {
    @apply hidden sm:block text-xl text-white hover:text-gray-400 font-bold tracking-tight;
  }
</style>
